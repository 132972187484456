.gnatta-inline-webchat-host-fullscreen {
  overflow-y: hidden;
  position: fixed;
}

.gnatta-webchat-hidden {
  display: none;
}

.gnatta-inline-webchat {
  max-width: 400px;
  max-height: 600px;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 15px;
  right: 45px;
  overflow: hidden;
  visibility: visible;
  z-index: 99999;
  border: 0;
}
.gnatta-inline-webchat iframe {
  width: 100%;
  height: 100%;
  border: 1px solid #e1e1e1;
  box-shadow: 0 24px 0;
}

.gnatta-inline-webchat-collapse {
  max-width: 400px;
  max-height: 46px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 9999;
}

@media screen and (max-width: 430px) {
  .gnatta-inline-webchat-collapse {
    width: 100%;
    max-width: 100%;
    max-height: 46px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 9999;
  }
}

.gnatta-inline-webchat.fullscreen {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border: 0;
  padding: 0;
  margin: 0;
  float: none;
  z-index: 9999;
  border: none;
}

.gnattaChat {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
}

.gnattaChat button {
  border-radius: 50%;
  width: 58px;
  height: 58px;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.4s cubic-bezier(0.2, 1.65, 0.55, 1.005);
  background-color: #000;
  border: solid 2px #fff;
  outline: none;
}

.gnattaChat button:focus-visible,
.gnattaChat button:focus {
  outline: none;
}

.gnattaChat button img {
  margin-top: 3px;
  width: 90%;
  height: 90%;
}

.gnattaChat button div.availabilityBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: solid 2px #fff;
  border-radius: 50%;
}

.gnattaChat button#gwc-chatAvailable div.availabilityBadge {
  background-color: #b1d234;
}

.gnattaChat button#gwc-chatBusy div.availabilityBadge,
.gnattaChat button#gwc-chatOffline div.availabilityBadge {
  background-color: #ed0f67;
}

.gnattaChat button.hidden {
  display: none;
}

.gnattaChat button:hover {
  transform: scale(1.175);
}
